/*eslint-disable*/
import React from "react";
import { Container, Row } from "reactstrap";

function DaysUntilFooter() {
  return (
    <>
      <footer className="footer">
        <Container>
          <Row>
            <div className="credits ml-auto">
              <span className="copyright">
                © {new Date().getFullYear()}
                , made with <i className="fa fa-heart heart" /> by Fannar Örn
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DaysUntilFooter;
