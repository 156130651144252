import React from "react";
import {
  Modal,
  NavbarBrand,
  Navbar,
  Container,
  Button
} from "reactstrap";


function DaysUntilNavbar(props) {
    const [loginModal, setLoginModal] = React.useState(false);

    return (
      <>
        <Modal
          isOpen={loginModal}
          toggle={() => setLoginModal(false)}
          modalClassName="modal-register"
        >
          <div className="modal-header no-border-header text-center">
            <h3 className="modal-title text-center">You are signed in as</h3>
            <h4 className="modal-title text-center">{props.currentUser.displayName}</h4>
          </div>
          <div id="loginBody" className="modal-body">
            <Button block className="btn-round" color="primary" onClick={props.signOut}>
              Sign Out
            </Button>
            <Button block className="btn-round" color="primary" onClick={() => setLoginModal(false)}>
              Go Back
            </Button>
          </div>
          <div className="modal-footer no-border-footer"></div>
        </Modal>

        <Navbar className="navbar-light bg-primary" expand="lg">
          <Container>
            <NavbarBrand href="/" onClick={e => e.preventDefault()}>
              Days Until
            </NavbarBrand>
            <img onClick={() => setLoginModal(true)} src={props.currentUser?.photoURL} alt="User" style={{borderRadius: 50, height: 40}}/>
          </Container>
        </Navbar>
      </>
    );
  }

export default DaysUntilNavbar