import React, { useEffect, useState } from "react";
import Datetime from "react-datetime";
import moment from "moment";
import enGb from 'moment/locale/en-gb';
import TagsInput from "components/TagsInput/TagsInput.js";
import {
  Badge,
  Card,
  Button,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Modal,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText
} from "reactstrap";
import Select from "react-select";
import firebase from 'firebase/app';
import 'firebase/firestore';

export const useInput = initialValue => {
  const [value, setValue] = useState(initialValue);
  return {
    value,
    setValue,
    reset: () => setValue(""),
    bind: {
      value,
      onChange: event => {
        setValue(event.target.value);
      }
    }
  };
};

Array.prototype.chunk = function(size) {
  let result = [];
  
  while(this.length) {
      result.push(this.splice(0, size));
  }
      
  return result;
}

function Events(props) {
  const [addEventModal, setAddEventModal] = useState(false);
  const [editEventModal, setEditEventModal] = useState(false);
  const [multipleSelect, setMultipleSelect] = useState(null);
  const [loading, setLoading] = useState(true);
  const [eventChunks, setEventChunks] = useState([]);
  const { value:header, bind:bindHeader, reset:resetHeader } = useInput('');
  const { value:description, bind:bindDescription, reset:resetDescription } = useInput('');
  const { value:party, bind:bindParty, reset:resetParty } = useInput('');
  const { value:emoji, bind:bindEmoji, reset:resetEmoji } = useInput('');
  const [date, setEventDate] = useState('');
  const [tags, setEventTags] = useState([]);

  const [editHeader, setEditHeader] = useState('');
  const [editDescription, setEditDescription] = useState('');
  const [editParty, setEditParty] = useState('');
  const [editEmoji, setEditEmoji] = useState('');
  const [editDate, setEditEventDate] = useState('');
  const [editTags, setEditEventTags] = useState([]);
  const [editEventId, setEditEventId] = useState('');

  const handleEditHeader = (event) => {
    setEditHeader(event.target.value);
  }

  const handleEditDescription = (event) => {
    setEditDescription(event.target.value);
  }

  const handleEditParty = (event) => {
    setEditParty(event.target.value);
  }

  const handleEditEmoji = (event) => {
    setEditEmoji(event.target.value);
  }

  const handleEditEventId = (event) => {
    setEditEventId(event.target.value);
  }
  
  useEffect(() => {

    const fetchEvents = async() => {

    try {

      const now = new Date();
      now.setHours(0, 0, 0, 0); // Set time to midnight
      
      // For snapshotToday (from midnight today until midnight next day)
      const nextDay = new Date(now);
      nextDay.setDate(nextDay.getDate() + 1); // Add one day
      nextDay.setHours(0, 0, 0, 0); // Set time to midnight next day
      
      const snapshotToday = await props.db.collectionGroup("events")
        .where("user", "==", props.currentUser.uid)
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(now))
        .where("date", "<", firebase.firestore.Timestamp.fromDate(nextDay))
        .orderBy('date', 'asc')
        .get();
      
      // For snapshotNew (from midnight next day)
      const snapshotUpcoming = await props.db.collectionGroup("events")
        .where("user", "==", props.currentUser.uid)
        .where("date", ">=", firebase.firestore.Timestamp.fromDate(nextDay))
        .orderBy('date', 'asc')
        .get();

      const snapshotPast = await props.db.collectionGroup("events")
        .where("user", "==", props.currentUser.uid)
        .where("date", "<", firebase.firestore.Timestamp.fromDate(now))
        .orderBy('date', 'desc')
        .get();

      const docsToday = snapshotToday.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;
        const daysUntilResult = daysUntil(data.date.toDate());
        const numberOfDays = daysUntilResult[0];
        const daysDescription = daysUntilResult[1];
        const numberOfDaysColor = daysUntilResult[2];
        return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
      });

      const docsUpcoming = snapshotUpcoming.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;
        const daysUntilResult = daysUntil(data.date.toDate());
        const numberOfDays = daysUntilResult[0];
        const daysDescription = daysUntilResult[1];
        const numberOfDaysColor = daysUntilResult[2];
        return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
      });

      const docsPast = snapshotPast.docs.map(doc => {
        const data = doc.data();
        const id = doc.id;
        const daysUntilResult = daysUntil(data.date.toDate());
        const numberOfDays = daysUntilResult[0];
        const daysDescription = daysUntilResult[1];
        const numberOfDaysColor = daysUntilResult[2];
        return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
      });

      const docs = (docsToday.concat(docsUpcoming)).concat(docsPast);

      setEventChunks(docs.chunk(3));
      setLoading(false);

    } catch(err) {
      console.error(err);
    }

    };

    if(props.currentUser) {
      fetchEvents();
    }

  }, [props.currentUser, props.db]);

  const fetchEditEvent = (eventId) => {

    const fetchEvent = async(eventId) => {

      try {

        const doc = await props.db.collection("users").doc(props.currentUser.uid).collection('events').doc(eventId).get();
        const id = doc.id;
        const data = doc.data();
        setEditHeader(data.header);
        setEditDescription(data.description);
        setEditParty(data.party);
        setEditEmoji(data.emoji);
        setEditEventId(id);
        setEditEventDate(data.date.toDate());
        setEditEventTags(data.tags);

      } catch(err) {
          console.error(err);
      }

    }

    fetchEvent(eventId);
    setEditEventModal(true);
  }

  const submitEditEvent = (event) => {

    const editEvent = async() => {

      try {

        const eventData = {
          header: editHeader,
          description: editDescription,
          date: firebase.firestore.Timestamp.fromDate(editDate),
          party: editParty,
          emoji: editEmoji,
          tags: editTags,
          user: props.currentUser.uid
        };

        await props.db.collection("users").doc(props.currentUser.uid).collection('events').doc(editEventId).update(eventData);

      } catch(err) {
          console.error(err);
      }

    };

    const fetchEvents = async() => {

      try {
  
        const now = new Date();
        now.setHours(0, 0, 0, 0); // Set time to midnight
        
        // For snapshotToday (from midnight today until midnight next day)
        const nextDay = new Date(now);
        nextDay.setDate(nextDay.getDate() + 1); // Add one day
        nextDay.setHours(0, 0, 0, 0); // Set time to midnight next day
        
        const snapshotToday = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(now))
          .where("date", "<", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
        
        // For snapshotNew (from midnight next day)
        const snapshotUpcoming = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
  
        const snapshotPast = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", "<", firebase.firestore.Timestamp.fromDate(now))
          .orderBy('date', 'desc')
          .get();
  
        const docsToday = snapshotToday.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsUpcoming = snapshotUpcoming.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsPast = snapshotPast.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docs = (docsToday.concat(docsUpcoming)).concat(docsPast);
  
        setEventChunks(docs.chunk(3));
        setLoading(false);
  
      } catch(err) {
        console.error(err);
      }
  
      };
  
      if(props.currentUser) {
        fetchEvents();
      }

    if(props.currentUser) {
      editEvent();
      setEditHeader('');
      setEditDescription('');
      setEditEventDate('');
      setEditEmoji('');
      setEditParty('');
      setEditEventTags([]);
      setEditEventId('');
      fetchEvents();
      setEditEventModal(false)
    }

    event.preventDefault();

  }


  const submitNewEvent = (event) => {

    const addEvent = async() => {

      try {

        const eventData = {
          header: header,
          description: description,
          date: firebase.firestore.Timestamp.fromDate(date),
          party: party,
          emoji: emoji,
          tags: tags,
          user: props.currentUser.uid
        };

        await props.db.collection("users").doc(props.currentUser.uid).collection("events").add(eventData);

      } catch(err) {
          console.error(err);
      }

    };

    const fetchEvents = async() => {

      try {
  
        const now = new Date();
        now.setHours(0, 0, 0, 0); // Set time to midnight
        
        // For snapshotToday (from midnight today until midnight next day)
        const nextDay = new Date(now);
        nextDay.setDate(nextDay.getDate() + 1); // Add one day
        nextDay.setHours(0, 0, 0, 0); // Set time to midnight next day
        
        const snapshotToday = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(now))
          .where("date", "<", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
        
        // For snapshotNew (from midnight next day)
        const snapshotUpcoming = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
  
        const snapshotPast = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", "<", firebase.firestore.Timestamp.fromDate(now))
          .orderBy('date', 'desc')
          .get();
  
        const docsToday = snapshotToday.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsUpcoming = snapshotUpcoming.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsPast = snapshotPast.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docs = (docsToday.concat(docsUpcoming)).concat(docsPast);
  
        setEventChunks(docs.chunk(3));
        setLoading(false);
  
      } catch(err) {
        console.error(err);
      }
  
      };
  
      if(props.currentUser) {
        fetchEvents();
      }

    if(props.currentUser) {
      addEvent();
      resetHeader();
      resetDescription();
      setEventDate('');
      resetEmoji();
      resetParty();
      setEventTags([]);
      fetchEvents();
      setAddEventModal(false)
    }

    event.preventDefault();

  }

  const submitDeleteEvent = (event) => {

    const deleteEvent = async() => {

      try {

        const eventData = {
          header: editHeader,
          description: editDescription,
          date: firebase.firestore.Timestamp.fromDate(editDate),
          party: editParty,
          emoji: editEmoji,
          tags: editTags,
          user: props.currentUser.uid
        };

        await props.db.collection("users").doc(props.currentUser.uid).collection('events').doc(editEventId).delete();

      } catch(err) {
          console.error(err);
      }

    };

    const fetchEvents = async() => {

      try {
  
        const now = new Date();
        now.setHours(0, 0, 0, 0); // Set time to midnight
        
        // For snapshotToday (from midnight today until midnight next day)
        const nextDay = new Date(now);
        nextDay.setDate(nextDay.getDate() + 1); // Add one day
        nextDay.setHours(0, 0, 0, 0); // Set time to midnight next day
        
        const snapshotToday = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(now))
          .where("date", "<", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
        
        // For snapshotNew (from midnight next day)
        const snapshotUpcoming = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", ">=", firebase.firestore.Timestamp.fromDate(nextDay))
          .orderBy('date', 'asc')
          .get();
  
        const snapshotPast = await props.db.collectionGroup("events")
          .where("user", "==", props.currentUser.uid)
          .where("date", "<", firebase.firestore.Timestamp.fromDate(now))
          .orderBy('date', 'desc')
          .get();
  
        const docsToday = snapshotToday.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsUpcoming = snapshotUpcoming.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docsPast = snapshotPast.docs.map(doc => {
          const data = doc.data();
          const id = doc.id;
          const daysUntilResult = daysUntil(data.date.toDate());
          const numberOfDays = daysUntilResult[0];
          const daysDescription = daysUntilResult[1];
          const numberOfDaysColor = daysUntilResult[2];
          return {id, numberOfDays, numberOfDaysColor, daysDescription, ...data}
        });
  
        const docs = (docsToday.concat(docsUpcoming)).concat(docsPast);
  
        setEventChunks(docs.chunk(3));
        setLoading(false);
  
      } catch(err) {
        console.error(err);
      }
  
      };
  
      if(props.currentUser) {
        fetchEvents();
      }

    if(props.currentUser) {
      deleteEvent();
      setEditHeader('');
      setEditDescription('');
      setEditEventDate('');
      setEditEmoji('');
      setEditParty('');
      setEditEventTags([]);
      setEditEventId('');
      fetchEvents();
      setEditEventModal(false)
    }

    event.preventDefault();

  }

  const updateDate = (moment) => {
    setEventDate(moment.toDate())
  }

  const updateEditDate = (moment) => {
    setEditEventDate(moment.toDate())
  }

  const daysUntil = (eventDate) => {

    var dateNow = new Date();
    dateNow.setHours(0,0,0,0);
    eventDate.setHours(0,0,0,0);
      
    // To calculate the time difference of two dates
    var Difference_In_Time = eventDate.getTime() - dateNow.getTime();
      
    // To calculate the no. of days between two dates
    var Difference_In_Days = (Difference_In_Time / (1000 * 3600 * 24)).toFixed(0);

    if(Difference_In_Days<0) {
      return [`${Difference_In_Days*-1}`, "days ago", "danger"]
    }
    else if(Difference_In_Days>0) {
      return [`${Difference_In_Days}`, "days until", "success"]
    }
    else {
      return [`${0}`, "Today", "warning"]
    }
  }

  return (
    <>
      <Modal
        isOpen={addEventModal}
        toggle={() => setAddEventModal(false)}
        modalClassName="modal-register"
      >
        <div className="modal-header no-border-header text-center">
          <h3 className="modal-title text-center">Add new event</h3>
        </div>
        <div id="addEventBody" className="modal-body">
          <Form onSubmit={submitNewEvent}>
            <FormGroup>
              <Input
                id="eventHeaderInput"
                placeholder="Enter header"
                type="text"
                {...bindHeader}
              ></Input>
            </FormGroup>
            <br/>
            <FormGroup>
              <Input
                id="eventDescriptionInput"
                placeholder="Enter description"
                type="text"
                {...bindDescription}
              ></Input>
            </FormGroup>
            <br/>
            {/* <FormGroup>
            <Input
                id="eventPersonInput"
                placeholder="Enter emoji for the person or group"
                type="text"
                {...bindEmoji}
              ></Input>
              <br/>
              <Input
                id="eventPartyInput"
                placeholder="Enter affected person or group name"
                type="text"
                {...bindParty}
              ></Input>
            </FormGroup>
            <br/> */}
            <FormGroup>
              <InputGroup className="date" id="eventDateTimeInput">
                <Datetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select the date and time of the event"
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat=""
                  onChange={updateDate}
                  locale={moment.locale()}
                  initialValue={new Date().toISOString().split('T')[0]}
                  input={false}
                />
                {/* <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span className="glyphicon glyphicon-calendar">
                      <i className="fa fa-calendar" />
                    </span>
                  </InputGroupText>
                </InputGroupAddon> */}
              </InputGroup>
            </FormGroup>
            <br/>
            {/* <TagsInput
              value={tags}
              onChange={value => setEventTags(value)}
              tagProps={{ className: "react-tagsinput-tag badge-primary" }}
            />
            <br/> */}
            <Button id="submitEvent" color="primary" type="submit">
              Submit
            </Button>
          </Form>
        </div>
        <div className="modal-footer no-border-footer"></div>
      </Modal>

      <Modal
        isOpen={editEventModal}
        toggle={() => setEditEventModal(false)}
        modalClassName="modal-register"
      >
        <div className="modal-header no-border-header text-center">
          <h3 className="modal-title text-center">Edit event</h3>
        </div>
        <div id="addEventBody" className="modal-body">
          <Form onSubmit={submitEditEvent}>
            <FormGroup>
              <Input
                id="eventHeaderInput"
                placeholder="Enter header"
                type="text"
                value={editHeader}
                onChange={handleEditHeader}
              ></Input>
            </FormGroup>
            <br/>
            <FormGroup>
              <Input
                id="eventDescriptionInput"
                placeholder="Enter description"
                type="text"
                value={editDescription}
                onChange={handleEditDescription}
              ></Input>
            </FormGroup>
            <br/>
            {/* <FormGroup>
            <Input
                id="eventPersonInput"
                placeholder="Enter emoji for the person or group"
                type="text"
                value={editEmoji}
                onChange={handleEditEmoji}
              ></Input>
              <br/>
              <Input
                id="eventPartyInput"
                placeholder="Enter affected person or group name"
                type="text"
                value={editParty}
                onChange={handleEditParty}
              ></Input>
            </FormGroup>
            <br/> */}
            <FormGroup>
              <InputGroup className="date" id="eventDateTimeInput">
                <Datetime
                  inputProps={{
                    className: "form-control",
                    placeholder: "Select the date and time of the event"
                  }}
                  dateFormat="YYYY-MM-DD"
                  timeFormat=""
                  onChange={updateEditDate}
                  locale={moment.locale()}
                  value={editDate}
                  input={false}
                />
                {/* <InputGroupAddon addonType="append">
                  <InputGroupText>
                    <span className="glyphicon glyphicon-calendar">
                      <i className="fa fa-calendar" />
                    </span>
                  </InputGroupText>
                </InputGroupAddon> */}
              </InputGroup>
            </FormGroup>
            <br/>
            {/* <TagsInput
              value={editTags}
              onChange={value => setEditEventTags(value)}
              tagProps={{ className: "react-tagsinput-tag badge-primary" }}
            />
            <br/> */}
            <Button id="submitEditEvent" color="primary" type="submit">
              Submit Changes
            </Button>
            <Button id="submitDeleteEvent" color="primary" onClick={submitDeleteEvent}>
              Delete Event
            </Button>
          </Form>
        </div>
        <div className="modal-footer no-border-footer"></div>
      </Modal>

      <Button id="addEvent" className="btn-just-icon" color="primary" size="lg" onClick={() => setAddEventModal(true)}>
        <i className="fa fa-plus"></i>
      </Button>

      {/*<Select
            className="react-select react-select-info"
            classNamePrefix="react-select"
            placeholder="Filter by tags"
            name="multipleSelect"
            closeMenuOnSelect={false}
            isMulti
            value={multipleSelect}
            onChange={value => setMultipleSelect(value)}
            options={[
              {
                value: "",
                label: " Multiple Options",
                isDisabled: true
              },
              { value: "2", label: "Paris " },
              { value: "3", label: "Bucharest" },
              { value: "4", label: "Rome" },
              { value: "5", label: "New York" },
              { value: "6", label: "Miami " },
              { value: "7", label: "Piatra Neamt" },
              { value: "8", label: "Paris " },
              { value: "9", label: "Bucharest" },
              { value: "10", label: "Rome" },
              { value: "11", label: "New York" },
              { value: "12", label: "Miami " },
              { value: "13", label: "Piatra Neamt" },
              { value: "14", label: "Paris " },
              { value: "15", label: "Bucharest" },
              { value: "16", label: "Rome" },
              { value: "17", label: "New York" },
              { value: "18", label: "Miami " },
              { value: "19", label: "Piatra Neamt" }
            ]}
          />*/}

      <div className="wrapper" style={{backgroundColor:'#6dd3d6'}}>
        <div className="section-events">
          <Container>

            {/*!loading && events.map((event, i) => 
              <div key={i}>
                <p className="layout">{event.header}</p>
                <ul className="Needed">
                  <li>{event.id}</li>
                  <li>{event.description}</li>
                  <li>{event.date.toDate().toLocaleDateString("is-IS")}</li>
                  <li>{daysUntil(event.date.toDate())}</li>
                </ul>
              </div>)
            */}

            {!loading && eventChunks.map((eventChunk, ci) => 
              <Row key={ci}>
                {eventChunk.map((event, ei) =>
                  <Col md="4" key={ei}>
                    <Card className="card-blog" onClick={() => fetchEditEvent(event.id)}>
                      <CardBody>
                        <div className="card-category-flex">
                          <h6 className={'card-category text-' + event.numberOfDaysColor}>{event.daysDescription}</h6>
                          {event.numberOfDays != 0 &&
                             <h6 className={'card-category card-category-flex-right text-' + event.numberOfDaysColor}>{event.numberOfDays}</h6>
                          }
                        </div>
                        <CardTitle tag="h5">{event.header}</CardTitle>
                        <p className="card-description">{event.description}</p>
                        {event.tags.map((tag, ti) =>
                          <Badge color="primary" key={ti} style={{marginRight: "5px"}}>{tag}</Badge>
                        )}
                        <hr style={{margin: "5px 0px"}}/>
                        <CardFooter>
                          <div className="author"><span className="emoji">{event.emoji}</span>{event.party}</div>
                          <div className="stats">
                            <i className="fa fa-clock-o mr-1" /> {event.date.toDate().toLocaleDateString("is-IS")}
                          </div>
                        </CardFooter>
                      </CardBody>
                    </Card>
                  </Col>
                )}
              </Row>
            )}
          </Container>
        </div>
      </div>
    </>
  );
}

export default Events;
