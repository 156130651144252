import React, { useEffect, useState } from 'react';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import { BrowserRouter, Route, Switch } from "react-router-dom";
import firebaseConfig from '../firebaseConfig';
import DaysUntilNavbar from "components/Navbars/DaysUntilNavbar";
import DaysUntilFooter from "components/Footers/DaysUntilFooter.js";
import Events from "./Events.js";

// Configure Firebase.
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();

// Configure FirebaseUI.
const uiConfig = {
  // Popup signin flow rather than redirect flow.
  signInFlow: 'popup',
  // We will display Google and Facebook as auth providers.
  signInOptions: [
    firebase.auth.GoogleAuthProvider.PROVIDER_ID
  ],
  callbacks: {
    // Avoid redirects after sign-in.
    signInSuccessWithAuthResult: () => false,
  },
};

function Index() {
  const [isSignedIn, setIsSignedIn] = useState(false); // Local signed-in state.

  document.documentElement.classList.remove("nav-open");

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
  });

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user => {
      setIsSignedIn(!!user);
    });
    return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
  }, []);

  const signOut = () => {
    firebase.auth().signOut();
  };

  if (!isSignedIn) {
    document.body.classList.add("start-page");
    return (
      <>
        <h1 style={{textAlign: 'center', color: 'white'}}>Days Until</h1>
        <br/>
        <StyledFirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
      </>
    );
  }
  document.body.classList.remove("start-page");
  return (
    
    <>
      <DaysUntilNavbar signOut={signOut} currentUser={firebase.auth().currentUser} />
      <div className="main">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={(props) => <Events currentUser={firebase.auth().currentUser} db={db} />} />
          </Switch>
        </BrowserRouter>
      </div>
      <DaysUntilFooter />
    </>
  );
}

export default Index;
